<template>
  <div>
    <div class="nbanner">
      <a :href="bannerPDF" download>
        {{ bannerEntitle }}
      </a>
      <div class="swiper-container2">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(v, index) in imageUrl" :key="index">
            <div class="li">
              <h3>{{ v.title }}</h3>
              <p>{{ v.subtitle }}</p>
              <img :src="v.photo" alt="" />
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="tabs">
      <ul class="f-no-s-center tabsTop">
        <li>
          <router-link class="titleColor" to="/esg"> 战略与行动 </router-link>
        </li>
        <li>
          <router-link class="titleColor on" to="/report" style="color: #396aff">
            报告与披露
          </router-link>
        </li>
        <li>
          <router-link class="titleColor" to="/statement">
            声明与制度
          </router-link>
        </li>
      </ul>
    </div>
    <div class="n-report">
      <div class="ul f-no">
        <a
          v-for="(item, index) in list"
          :key="index"
          :href="item.files"
          class="li"
        >
          <div class="img por ovh">
            <img :src="item.photo" alt="" />
          </div>
          <div class="p" v-html="item.title"></div>
        </a>
      </div>
      <div class="botbtn" @click="change" v-if="total > pageNo">查看更多</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cur"],
  data() {
    return {
      pageNo: 1,
      total: 0,
      list: [],
      active: 1,
      imageUrl: "",
      bannerPDF: null,
      bannerEntitle: "",
      bannerSwiper: null,
    };
  },
  mounted() {
    //获取到配置的头部颜色，听过store监听动态改变
    this.$store.commit("until/SET_COLOR", "#000");
    this.id = this.$route.query.id;
    this.selected = this.$route.query.id;
    this.getData(this.pageNo);
    this.getBanner();
    // this.initSwiper()
  },
  watch: {
    // cur(val){
    //    this.id = val
    //    this.selected = val
    //    this.getData()
    // }
  },
  methods: {
    initSwiper() {
      this.bannerSwiper = new Swiper(".swiper-container2", {
        autoplay: 3000,
        slidesPerView: 1,
        pagination: ".swiper-pagination",
      });
    },
    getData() {
      this.$fetch({
        url: "/index.php/esg/reporting/" + this.pageNo,
        type: "post",
        data: this.$qs.stringify({
          // pageNo: 10,
          // pageSize: this.count,
          // ctype: this.ctype,
          // cid: this.cid,
        }),
      }).then((res) => {
        this.total = res.data.list.count;
        this.list = [...this.list, ...res.data.list.data];
        this.$nextTick(() => {
          setTimeout(function () {
            this.bannerSwiper = new Swiper(".swiper-container2", {
              autoplay: 3000,
              slidesPerView: 1,
              pagination: ".swiper-pagination",
              paginationClickable: true,
            });
          }, 1000);
        });
      });
    },

    change() {
      if (this.pageNo < this.total) {
        this.pageNo++;
        this.getData();
      }
    },
    getBanner() {
      this.$fetch({
        url: "/index.php/esg/banner",
        type: "post",
      }).then((res) => {
        this.imageUrl = res.data.banner_list;
        this.bannerPDF = res.data.banner_pdf.files;
        this.bannerEntitle = res.data.banner_pdf.entitle;
      });
    },
    // tolink(id) {
    //   this.$router.push({
    //     path: "/newsview",
    //     query: { id: id, ctype: this.ctype },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.por {
  position: relative;
}
.ovh {
  overflow: hidden;
}
.f-no-s-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.tabsTop {
  left: 160px;
}
.nbanner {
  position: relative;
  overflow: hidden;
  img {
    //height: 560px;
    width: 100%;
  }
  a {
    z-index: 999;
    display: block;
    background-color: #396aff;
    width: 180px;
    color: #fff;
    height: 45px;
    font-size: 15px;
    line-height: 45px;
    text-align: center;
    position: absolute;
    left: 45%;
    top: 53%;
  }
  h3 {
    position: absolute;
    left: 20%;
    font-size: 42px;
    top: 50%;
  }
  p {
    margin: 27px 0 60px 0;
    position: absolute;
    left: 20%;
    font-weight: normal;
    color: #c6c6c6;
    font-size: 18px;
    top: 58%;
  }
  .swiper-pagination {
    position: absolute;
    z-index: 1;
    bottom: 20px;
  }
}
.titleColor:hover {
  color: #396aff;
}
.n-report {
  padding: 96px 0 108px;
  background: #fff;
  z-index: 1;
  .ul {
    width: 1200px;
    margin: 0 auto;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .li {
      width: 22%;
      margin-right: 4%;
      margin-bottom: 60px;
      display: block;
      .img {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale3d(1, 1, 1);
          transition: all 0.6s;
        }
      }
      .p {
        margin-top: 20px;
        line-height: 1.4;
        color: #000;
        font-size: 20px;
      }
    }
    .li:hover {
      .img {
        img {
          transform: scale3d(1.05, 1.05, 1);
        }
      }
    }
    .li:nth-child(4n) {
      margin-right: 0;
    }
  }
  .botbtn {
    width: 120px;
    height: 40px;
    border: 1px solid #396aff;
    color: #396aff;
    text-align: center;
    line-height: 36px;
    margin: 30px auto 0;
    cursor: pointer;
    transition: all 0.6s;
  }
  .botbtn:hover {
    background: #396aff;
    color: #fff;
  }
}
.tabs {
  background: rgb(252, 253, 253);
  font-size: 14px;
  color: #333333;
  // position: sticky;
  // top: 70px;
  z-index: 5;
  ul {
    height: 70px;
    margin: 0 auto;
    li {
      margin-right: 60px;
      a {
        font-weight: 600;
        height: 60px;
        line-height: 60px;
        display: block;
        position: relative;
        padding: 0 20px;
      }
      
    }
    .on {
      color: #396aff;
    }
    a.on::after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: rgb(57, 106, 255);
      content: "";
    }
  }
}

@media (min-width: 1280px) {
  .tabs {
    background: rgb(252, 253, 253);
    font-size: 14px;
    color: #333333;
    z-index: 5;
    ul {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      li {
        margin-right: 60px;
      }
      .on {
        color: #396aff;
      }
    }
  }
}
</style>